.daily-ui-container {
    padding-bottom: 100px;
    padding-top: 40px;
}

.daily-ui-container h3 {
    color: #0C7EA3;
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: 700;
}

.sign-up-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 150px;
}

.daily-ui-phones {
    display: flex;
    justify-content: space-evenly;
}

.card-checkout,
.calculator{
    display: flex;
    flex-direction: column;
    align-items: center;
}