body {
    font-family: 'Montserrat', sans-serif;
    background-color: #EFECE6;
    height: 100vh;
}

.App,
#root {
    height: 100%;
    width: 100%;

}




body {
    padding-top: 39px;
}

.navbar {
    padding: 0;
}

.navbar,
.nav-link {
    color: #0C7EA3;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Montserrat Alternates', sans-serif;

}

.nav-item {
    margin-right: 48px;
}

.flex-grow-0 {
    margin-left: 52px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 133px;
    height: 65px;

}

.navbar-collapse {
    flex-basis: 0;
}

.container-fluid {
    padding: 0;
}


.logo-contact {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 133px;
    height: 65px;
    top: 0;
    left: 80px;
}


.logo-left-piece,
.logo-right-piece {
    width: 72.25px;
    height: 65px;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;

}


.logo-left-piece {
    background-image: url('./images/project-puzzle-pieces/puzzle-left-piece-logo.png');
}

.logo-right-piece {
    background-image: url('./images/project-puzzle-pieces/puzzle-right-piece-logo.png');
}

.logo-left-piece {
    left: 0;
}

.logo-right-piece {
    right: 0;
}

main {
    width: 100%;
    height: 80%;
}

.home-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-intro {
    color: #0C7EA3;
    width: 381px;
    height: 174px;
    font-size: 20px;

}

.home-intro h1 {
    font-weight: 700;
    font-family: 'Montserrat Alternates', sans-serif;

}

.home-intro p {
    font-weight: 600;
}

.home-image-container {
    width: 760px;
    height: 540px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}


.home-image {
    position: absolute;
    width: 270px;
    height: 320px;
    border-radius: 20px;
}

.home-image-background {
    position: absolute;
    width: 500px;
    height: 500px;
}

.projects-puzzle-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.projects-puzzle-pieces {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 372px;
    height: 100%;
    position: relative;
}

.projects-left-piece,
.projects-right-piece {
    position: absolute;
    background-repeat: no-repeat;
    width: 200px;
    height: 200px;
    background-size: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}



.projects-left-piece span {
    position: absolute;
    width: 50%;
    text-align: center;
    color: #0C7EA3;
    font-size: 24px;
    font-weight: 600;
    z-index: 3 !important;
}

.projects-right-piece span {
    position: absolute;
    width: 100%;
    text-align: center;
    color: #0C7EA3;
    font-size: 24px;
    font-weight: 600;
    z-index: 3 !important;
}

.projects-left-piece {
    background-image: url('./images/project-puzzle-pieces/projects-puzzle-left-piece.png');
    left: 0;
}

.projects-right-piece {
    background-image: url('./images/project-puzzle-pieces/projects-puzzle-right-piece.png');
    right: 0;
}

.projects-left-piece:hover,
.projects-right-piece:hover {
    filter: brightness(0.95);
}

#gd-projects-left-piece {
    background-image: url('./images/project-puzzle-pieces/graphic-design-puzzle-left-piece.png');
}

#gd-projects-right-piece {
    background-image: url('./images/project-puzzle-pieces/graphic-design-puzzle-right-piece.png');
}

#personal-projects {
    width: 100%;
}


.about-me-container {
    padding-top: 60px;
    color: #0C7EA3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
}

.about-me-container h2 {
    font-weight: 600;
    margin-top: 40px;
}

.about-me {
    width: 980px;
}

.intro-container {
    width: 700px;
    height: 500px;
    position: relative;
    margin-bottom: 60px;
}

.intro-container h1 {
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 700;
}

.intro-container p {
    margin-top: 20px;
    font-size: 22px;
}

.intro-container a {
    text-decoration: none;
    color: #0C7EA3;
}

.intro-container a:hover {
    color: #9EDCE1;
}

.intro-container::after {
    content: '';
    background-color: #0C7EA3;
    height: 2px;
    width: 980px;
    position: absolute;
    bottom: 0;
}

.education-experience-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;
}

.education-experience-container h2 {
    font-family: 'Montserrat Alternates', sans-serif;

}


.education-experience-container ul {
    margin-top: 20px;
    list-style: none;
    font-size: 22px;
    font-weight: 600;
    padding: 0;
}

.education-experience-container li {
    font-weight: 500;
    font-size: 18px;
}

.skills {
    list-style: disc !important;
    padding-left: 32px !important;
}

.skills li {
    margin-bottom: 15px;
}



.contact-container {
    height: 300px;
    color: #0C7EA3;
    font-weight: 600;
    font-size: 20px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 150px;
    width: 980px;
}

.contact-inner {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;

}

.contact-container::after {
    content: '';
    background-color: #0C7EA3;
    width: 980px;
    height: 2px;
    position: absolute;
    top: 0;

}

.contact span {
    font-size: 18px;
    margin-top: 10px;
}

.contact a {
    text-decoration: none;
    color: #0C7EA3;
}

.contact a:hover {
    color: #9EDCE1;
    filter: brightness(0.94);
}

.contact {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
}


.bi-arrow-up-circle {
    position: fixed;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    color: #0C7EA3;
}


.contact-page-container{
    width: 100%;
    display: flex;
    justify-content: center;
}



@media screen and (max-width:1250px) {
    .home-image-container {
        width: 550px;
        height: 400px;
    }

    .home-image-background {
        width: 400px;
        height: 400px;
    }

    .home-image {
        width: 220px;
        height: 270px;
    }

    .home-container {
        padding: 20px;

    }
}

@media screen and (max-width:900px) {

    .home-container {
        flex-direction: column;
        height: 120vh;
        align-items: center;
    }


}




@media screen and (min-width:300px) and (max-width:425px) {

    .home-intro {
        width: 250px;
        height: 300px;
        margin-bottom: 50px;
    }


    .home-image-container {
        width: 300px;
        height: 300px;
    }

    .home-image-background {
        width: 300px;
        height: 300px;
    }

    .home-image {
        width: 150px;
        height: 200px;
    }

    .about-me-container {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }


    .about-me {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-me h2 {
        margin-top: 0;
    }

    .about-me ul {
        margin-top: 10px;
    }

    .about-me-container p,
    .about-me-container li,
    .about-me-container span {
        font-size: 14px;
    }

    .intro-container {
        width: 250px;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }


    .intro-container::after,
    .contact-container::after,
    .business-card-container::after,
    .image-poster-container::after {
        width: 250px !important;
    }

    .education,
    .experience-container,
    .education-experience-container {
        flex-direction: column;
        display: flex;
        width: 250px;
    }

    .experience-container,
    .education {
        align-items: start;
    }

    .contact-container,
    .contact-inner {
        width: 250px;

    }

    .contact-container {
        height: 300px;
        margin-bottom: 50px !important;
    }

    .contact-inner {
        height: 200px;
        flex-direction: column;
        justify-content: space-between;

    }

    .contact span {
        font-size: 14px !important;
    }

    .logo-contact {
        top: 180px !important;
        left: 90px !important;
        margin-left: 0 !important;
    }

    .bi-arrow-up-circle {
        right: 20px !important;
    }


    .jam,
    .business-card {
        width: 250px !important;
        height: 200px !important;
    }

    .jams-container {
        height: 700px !important;
    }

    .image-poster {
        width: 300px !important;
    }


    .daily-ui-container {
        padding-bottom: 0;
    }

    .sign-up-page {
        margin-bottom: 0 !important;
    }

    .sign-up-page img {
        width: 280px !important;
        height: 180px !important;
    }

    .card-checkout img,
    .calculator img {
        width: 250px !important;
        height: 450px !important;
    }



    .grocery-pal li,
    .grocery-pal p {
        font-size: 14px !important;
    }

    .grocery-pal-images {
        flex-direction: column;
        align-items: center;
        height: 1540px;
    }

    .grocery-pal-image {
        width: 260px;
        height: 480px;
    }

    .grocery-pal-info-container,
    .grocery-pal-info-inner,
    .grocery-pal-user-story-container,
    .grocery-pal-user-story-container ul,
    .grocery-pal-user-story-container li,
    .grocery-pal-user-story-container h3,
    .grocery-pal-usability-container img,
    .grocery-pal-storyboard img {
        width: 300px !important;
    }

    .persona-container {
        width: 300px !important;
        height: 2700px !important;
        flex-direction: column;
        align-items: center;
    }

    .person-container {
        width: 260px;
    }

    .persona-person-container {
        width: 260px !important;
        height: 1200px !important;
    }

    .persona-detail-description-container {
        width: 260px !important;
        height: 1400px !important;
    }

    .personality-container {
        height: 260px !important;
    }

    .person-image {
        width: 140px !important;
        height: 180px !important;
    }

    .grocery-pal-user-journey img,
    .grocery-pal-design-system,
    .grocery-pal-moodboard,
    .grocery-pal-wireframes,
    .grocery-pal-design-image {
        width: 300px !important;
    }

    .persona-methods-container {
        width: 260px !important;
    }

    .persona-methods {
        width: 120px !important;
        text-align: center;
    }

    .persona-methods h4 {
        font-size: 14px;
    }

    .person-description p,
    .persona-detail-description-container p,
    .persona-detail-description-container li {
        font-size: 14px !important;
    }


    .persona-biography-container,
    .persona-needs-container {
        height: 300px !important;
    }

    .persona-frustrations-container {
        height: 500px !important;
    }
}

@media screen and (min-width:768px) and (max-width:991px) {
    .sign-up-page img {
        width: 750px !important;
        height: 480px !important;
    }

    .sign-up-page {
        margin: 0 !important;
    }
}

@media screen and (min-width:300px) and (max-width:991px) {
    .navbar {
        flex-wrap: nowrap;
        margin: 0;
        width: 100%;
    }

    .container-fluid {
        justify-content: space-between !important;
    }

    .nav-item {
        display: flex;
        justify-content: center;
        margin-right: 0;
    }

    .nav-link {
        font-size: 16px;
        z-index: 1;

    }

    .navbar {
        padding: 10px;
    }




    .flex-grow-0 {
        margin-left: 0;

    }


    .daily-ui-phones {
        flex-direction: column;
    }

}


@media screen and (max-width:768px) {

    .projects-left-piece,
    .projects-right-piece {
        width: 120px;
        height: 120px;
    }

    .projects-left-piece span,
    .projects-right-piece span {
        font-size: 16px;
    }

    .projects-puzzle-pieces {
        width: 220px;
    }
}

@media screen and (min-width:769px) and (max-width:991px) {
    .home-intro {
        margin-bottom: 70px !important;

    }
}


@media screen and (min-width:425px) and (max-width:768px) {

    .home-intro {
        width: 350px;
        height: 200px;
        margin-bottom: 70px;
    }


    .home-image-container {
        width: 350px;
        height: 350px;
    }

    .home-image-background {
        width: 350px;
        height: 350px;
    }

    .home-image {
        width: 200px;
        height: 250px;
    }


    .about-me-container {
        padding-left: 20px;
        padding-right: 20px;
    }



    .about-me {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-me h2 {
        margin-top: 0;
    }

    .about-me ul {
        margin-top: 10px;
    }




    .intro-container {
        width: 420px;
        height: 700px;
    }


    .intro-container::after,
    .contact-container::after,
    .business-card-container::after,
    .image-poster-container::after {
        width: 420px !important;
    }


    .education,
    .experience-container,
    .education-experience-container {
        flex-direction: column;
        display: flex;
        width: 420px;
    }

    .experience-container,
    .education {
        align-items: start;
    }

    .contact-container,
    .contact-inner {
        width: 420px;

    }


    .contact-container {
        height: 300px;
        margin-bottom: 50px !important;
    }


    .contact-inner {
        height: 200px;
        flex-direction: column;
        justify-content: space-between;

    }

    .logo-contact {
        top: 180px !important;
        left: 200px !important;
        margin-left: 0 !important;
    }

    .bi-arrow-up-circle {
        right: 30px !important;
    }



    .jam,
    .business-card {
        width: 400px !important;
        height: 320px !important;
    }

    .jams-container {
        height: 1100px !important;
    }

    .image-poster {
        width: 400px !important;
    }


    .daily-ui-container {
        padding-bottom: 0;
    }

    .sign-up-page {
        margin-bottom: 0 !important;
    }

    .sign-up-page img {
        width: 400px !important;
        height: 260px !important;
    }

    .card-checkout img,
    .calculator img {
        width: 250px !important;
        height: 450px !important;
    }


    .grocery-pal-images {
        flex-direction: column;
        align-items: center;
        height: 1540px;
    }

    .grocery-pal-image {
        width: 260px;
        height: 480px;
    }

    .grocery-pal-info-container,
    .grocery-pal-info-inner,
    .grocery-pal-user-story-container,
    .grocery-pal-user-story-container ul,
    .grocery-pal-user-story-container h3,
    .grocery-pal-usability-container img,
    .grocery-pal-storyboard img {
        width: 420px !important;
    }

    .grocery-pal-user-story-container li {
        width: 410px !important;
        margin-bottom: 10px;
    }

    .grocery-pal-user-story-container ul {
        height: 300px !important;
    }

    .persona-container {
        width: 420px !important;
        height: 2600px !important;
        flex-direction: column;
    }

    .person-container {
        width: 360px;
    }

    .persona-person-container {
        width: 360px !important;
        height: 1200px !important;
    }

    .persona-detail-description-container {
        width: 360px !important;
        height: 1300px !important;
    }

    .personality-container {
        height: 250px !important;
    }

    .person-image {
        width: 140px !important;
        height: 180px !important;
    }

    .grocery-pal-user-journey img,
    .grocery-pal-design-system,
    .grocery-pal-moodboard,
    .grocery-pal-wireframes,
    .grocery-pal-design-image {
        width: 420px !important;
    }

    .persona-methods {
        width: 160px !important;
        text-align: center;
    }

    .persona-methods h4 {
        font-size: 16px;
    }

    .person-description p,
    .persona-detail-description-container p,
    .persona-detail-description-container li {
        font-size: 17px !important;
    }




}



@media screen and (min-width:769px) and (max-width:1024px) {



    .about-me-container {
        padding-left: 20px;
        padding-right: 20px;
    }



    .about-me {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-me h2 {
        margin-top: 0;
    }



    .intro-container {
        width: 700px;
        height: 500px;
    }


    .intro-container::after,
    .contact-container::after,
    .business-card-container::after,
    .image-poster-container::after {
        width: 700px !important;
    }



    .education-experience-container {
        width: 700px;
    }



    .contact-container,
    .contact-inner {
        width: 700px;

    }


    .contact-container {
        height: 300px;
        margin-bottom: 100px !important;
    }


    .logo-contact {
        margin-left: 0 !important;
        left: 0 !important;

    }

    .jam,
    .business-card {
        width: 550px !important;
        height: 420px !important;
    }

    .jams-container {
        height: 1400px !important;
    }

    .image-poster {
        width: 580px !important;
    }





    .grocery-pal-image {
        width: 220px;
        height: 470px;
    }

    .grocery-pal-info-container,
    .grocery-pal-info-inner,
    .grocery-pal-user-story-container,
    .grocery-pal-user-story-container ul,
    .grocery-pal-user-story-container li,
    .grocery-pal-user-story-container h3,
    .grocery-pal-usability-container img,
    .grocery-pal-storyboard img {
        width: 700px !important;
    }

    .persona-container {
        width: 750px !important;
    }

    .persona-person-container {
        width: 240px !important;
    }

    .persona-detail-description-container {
        width: 420px !important;
    }

    .person-image {
        width: 188px !important;
        height: 252px !important;
    }

    .grocery-pal-user-journey img,
    .grocery-pal-design-system,
    .grocery-pal-moodboard,
    .grocery-pal-wireframes,
    .grocery-pal-design-image {
        width: 700px !important;
    }

    .persona-methods {
        width: 200px !important;
        text-align: center;
    }

    .person-description p,
    .persona-detail-description-container p,
    .persona-detail-description-container li {
        font-size: 17px !important;
    }




}

@media screen and (min-width:850px) and (max-width:1024px) {

    .grocery-pal-info-container,
    .grocery-pal-info-inner,
    .persona-container,
    .grocery-pal-user-story-container,
    .grocery-pal-user-story-container ul,
    .grocery-pal-user-story-container li,
    .grocery-pal-user-story-container h3,
    .grocery-pal-usability-container img,
    .grocery-pal-storyboard img {
        width: 800px !important;
    }

    .persona-person-container {
        width: 250px !important;
    }

    .persona-detail-description-container {
        width: 460px !important;
    }

    .person-image {
        width: 188px !important;
        height: 252px !important;
    }

    .grocery-pal-user-journey img,
    .grocery-pal-design-system,
    .grocery-pal-moodboard,
    .grocery-pal-wireframes,
    .grocery-pal-design-image {
        width: 800px !important;
    }

    .persona-methods {
        width: 215px !important;
        text-align: center;
    }

    .person-description p {
        font-size: 18px !important;
    }
}


@media screen and (min-width:1024px) {

    .about-me-container {
        padding-left: 40px;
        padding-right: 40px;
    }


    .intro-container {
        width: 700px;
        height: 500px;
    }

}