.personal-projects-container h1 {
    text-align: center;
    font-weight: 700;
    
}

.personal-projects-container {
    align-items: center;
    color: #0C7EA3;
    padding-top: 60px;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: 600;

}

.personal-projects-container,
.jams-container,
.image-poster-container,
.business-card-container {
    display: flex;
    flex-direction: column;
}

.jams-container {
    height: 1800px;
    justify-content: space-between;
    margin-bottom: 48px;
}

.jams-container h1 {
    margin-bottom: 0;
}



.business-card-container,
.image-poster-container {
    margin-top: 48px;
    margin-bottom: 48px;
    position: relative;
    display: flex;
    align-items: center;
}

.business-card-container::after,
.image-poster-container::after {
    content: '';
    background-color: #0C7EA3;
    width: 980px;
    height: 2px;
    position: absolute;
    top: -48px;
}

.business-card-container h1,
.image-poster-container h1{
    margin-bottom: 48px;

}


.jam,
.business-card {
    width: 650px;
    height: 520px;
}




