

.grocery-pal {
    display: flex;
    justify-content: center;
}

.grocery-pal p,
li {
    font-size: 20px;
}

.grocery-pal-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #0C7EA3;
}

.grocery-pal-images {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 39px;
    margin-bottom: 78px;
}

.grocery-pal-info-inner,
.grocery-pal-user-story-container,
.grocery-pal-user-story * {
    width: 980px;
}

.grocery-pal-user-journey div {
    display: flex;
    flex-direction: column;
    align-items: center;
}




.grocery-pal-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grocery-pal-info-container p {
    margin: 0;
}

.grocery-pal-info-container h1,
.grocery-pal-info-container h3,
.grocery-pal-info-container h4 {
    margin-bottom: 20px;
    margin-top: 20px;

    font-weight: 600;
}



.grocery-pal-details-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#project-overview {
    width: 700px;
}

.persona-container h4 {
    margin-bottom: 20px;
}

.persona-container {
    background-color: #13503A;
    color: black;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    height: 1300px;
    width: 1050px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.person-container {
    display: flex;
    align-items: center;
    height: 650px;
    background-color: white;
    border-radius: 30px;
    padding: 20px;
}

.person {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px 0 20px;
    width: 100%;

}

.persona-person-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 340px;

}

.person-name-image-container {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.person-description-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.person-info-description {
    display: flex;
    flex-direction: column;
}

.person-info-description {
    color: rgba(0, 0, 0, 0.41);
    font-weight: 600;
    width: 105px;
}

.person-info-description span {
    margin-bottom: 20px;
    height: 30px;
    display: flex;
    align-items: center;
}

#person-info {
    color: rgba(0, 0, 0, 0.85);
}

.person-description {
    background-color: white;
    border-radius: 30px;
    height: 250px;
    padding: 20px;
    display: flex;
    align-items: center;
}

.personality-container {
    background-color: white;
    border-radius: 30px;
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
}


#personality {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 5px 10px;
    border-radius: 40px;
    margin-bottom: 18px;
    cursor: pointer;
}

#personality:hover {
    background-color: rgba(0, 0, 0, 0.15);
}



.persona-detail-description-container {
    width: 620px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.persona-biography-container,
.persona-needs-container {
    border-radius: 30px;
    background-color: white;
    height: 250px;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}




.persona-needs-list li,
.persona-frustrations-list li {
    margin-bottom: 20px;
}

.persona-frustrations-container {
    border-radius: 30px;
    background-color: white;
    height: 450px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.persona-methods-container {
    display: flex;
    width: 100%;
    height: 200px;
    justify-content: space-between;
}

.persona-methods {
    width: 295px;
    height: 100%;
    background-color: white;
    border-radius: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.persona-method {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 5px 10px;
    border-radius: 40px;
    margin-bottom: 18px;
    cursor: pointer;
}

.persona-method:hover {
    background-color: rgba(0, 0, 0, 0.15);

}

#grocery-pal-design-image {
    height: 427px;
}

.grocery-pal-usability-container img {
    margin-bottom: 20px;
    margin-top: 20px;
}

#usability-list {
    list-style: none;
}



.grocery-pal-storyboard, 
.grocery-pal-storyboard h3,
.grocery-pal-storyboard h1{
    margin-top: 40px;
}

#low-fidelity-wireframe{
    margin-top: 0;
}

#usability-testing{
    margin-top: 40px;
}






